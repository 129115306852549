<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="none" name="plantCd" :disabled="plantDisabled" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="평가기간"
            name="assessmentDates"
            v-model="searchParam.assessmentDates"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="RAM_ASSESS_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ramAssessTypeCd"
            label="평가구분-정기/수시/최초"
            v-model="searchParam.ramAssessTypeCd"
          ></c-select>
        </div> -->
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="RAM_TECHNIQUE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ramTechniqueCd"
            label="평가기법"
            v-model="searchParam.ramTechniqueCd"
          ></c-select>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process
            label="LBLPROCESS"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            stepperGrpCd="IBM_STEP_CD"
            type="search"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="ibmStepCd"
            label="개선진행단계"
            v-model="searchParam.ibmStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
      <!-- checkDisableColumn="riskRegisterFlag" -->
    <c-table
      ref="table"
      title="위험등록부 목록"
      tableId="riskRegister01"
      :columns="gridRisk.columns"
      :data="gridRisk.data" 
      :gridHeight="gridRisk.height"
      :merge="gridRisk.merge"
      :editable="editable"
      @rowDblclick="rowDblclick"
      :selection="popupParam.type"
      rowKey="ramSignificantRiskRegisterId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props }">
        <template v-if="props.row['sopImprovementIds'] && props.row['ibmStepNames']">
          <q-chip
            v-for="(item, index) in chipDatas(props.row['sopImprovementIds'], props.row['ibmStepNames'])"
            :key="index"
            outline square
            :color="item.color"
            :clickable="true"
            text-color="white"
            style="margin-bottom:4px !important;"
            @click="openImpr(item, props.row)">
            {{item.title}}
          </q-chip>
        </template>
        <template v-else>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'assess-risk-register-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
          processCd: '',
          mdmSopId: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        ramTechniqueCd: 'RT00000025',
        processCd: null,
        ibmStepCd: null,
        mdmSopId: null,
      },
      gridRisk: {
        merge: [
          { index: 0, colName: 'assessmentName' },
          { index: 1, colName: 'processName' },
          { index: 2, colName: 'jobName' },
        ],
        height: '',
        data: [],
        columns: [
          {
            name: 'assessmentName',
            field: 'assessmentName',
            label: '평가명',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'left',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: 'LBLJOB',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'ram4mRiskHazardClassName',
            field: 'ram4mRiskHazardClassName',
            label: '평가구분(4M)',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            label: '유해위험요인',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'damageTargetName',
            field: 'damageTargetName',
            label: '피해대상',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'damageTypeName',
            field: 'damageTypeName',
            label: '재해형태',
            align: 'left',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'existingRiskManagementActivities',
            field: 'existingRiskManagementActivities',
            label: '기존 리스크관리 활동',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            label: '개선전',
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                label: '빈도',
                align: 'center',
                style: 'width:50px',
                sortable: false
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                label: '강도',
                align: 'center',
                style: 'width:50px',
                sortable: false
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                label: '위험도',
                align: 'center',
                style: 'width:70px',
                sortable: false,
              },
            ]
          },
          {
            name: 'addingRiskManagementActivities',
            field: 'addingRiskManagementActivities',
            label: '추가 리스크관리 계획',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'after',
            field: 'after',
            label: '개선후',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                label: '빈도',
                align: 'center',
                style: 'width:50px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                label: '강도',
                align: 'center',
                style: 'width:50px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                label: '위험도',
                align: 'center',
                style: 'width:70px',
                sortable: false,
              },
            ]
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            label: '평가일',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            label: '평가자',
            align: 'center',
            userId: 'assessUserId',
            style: 'width:80px',
            sortable: false,
          },
          // {
          //   name: 'impr',
          //   field: 'impr',
          //   label: '개선진행',
          //   align: 'center',
          //   child: [
          //     {
          //       name: 'customCol',
          //       field: 'customCol',
          //       label: 'LBLIMPRNO_PROGRESS',
          //       align: 'center',
          //       style: 'width:150px',
          //       type: 'custom',
          //       sortable: false
          //     },
          //   ]
          // },
        ],
      },
      colorItems: [],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      requestImprRow: null,
      imprData: null,
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    plantDisabled() {
      return Boolean(this.popupParam.plantCd)
    },
    processDisabled() {
      return Boolean(this.popupParam.processCd)
    },
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.gridRisk.height = height + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.fm.riskRegister.list.url;
      // code setting
      // this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
      //   this.colorItems = _result
      // });
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.$_.clone(this.popupParam.plantCd)
      }
      if (this.popupParam.processCd) {
        this.searchParam.processCd = this.$_.clone(this.popupParam.processCd)
      }
      if (this.popupParam.mdmSopId) {
        this.searchParam.mdmSopId = this.$_.clone(this.popupParam.mdmSopId)
      }
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridRisk.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      // RT00000001	HAZOP         
      // RT00000005	K-PSR         
      // RT00000010	Check-list    
      // RT00000015	JSA           
      // RT00000020	KRAS          
      // RT00000025	4M            
      let pageSrc = '';
      switch (row.ramTechniqueCd) {
        case 'RT00000001':
          pageSrc = '@/pages/ram/hazop/hazopDetail'
          break;
        case 'RT00000005':
          pageSrc = '@/pages/ram/kpsr/kpsrDetail'
          break;
        case 'RT00000010':
          pageSrc = '@/pages/ram/checklist/checklistDetail'
          break;
        case 'RT00000015':
          pageSrc = '@/pages/ram/jsa/jsaDetail'
          break;
        case 'RT00000020':
          pageSrc = '@/pages/ram/kras/krasDetail'
          break;
        case 'RT00000025':
          pageSrc = '@/pages/ram/4m/4mDetail'
          break;
      }
      this.popupOptions.title = '위험성평가 상세'; // HAZOP 상세
      this.popupOptions.param = {
        ramRiskAssessmentPlanId: row.ramRiskAssessmentPlanId,
        ramStepCd: row.ramStepCd,
      };
      this.popupOptions.target = () => import(`${pageSrc}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    chipDatas(sopImprovementIds, ibmStepNames) {
      let returnData = [];
      let splitId = this.$_.split(sopImprovementIds, ',');
      let splitStep = this.$_.split(ibmStepNames, ',');

      if (splitId.length === splitStep.length) {
        let i = 0;
        this.$_.forEach(splitId, item => {
          let color = this.$_.find(this.colorItems, { stepperMstNm: splitStep[i] });
          returnData.push({
            title: item + ' / ' + splitStep[i],
            color: color ? color.colorClass : '',
            sopImprovementId: item,
          });
          i++;
        });
      }
      return returnData;
    },
    openImpr(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      this.popupOptions.title = 'LBLIMPROVE';
      this.popupOptions.param = {
        sopImprovementId: data.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (result.col1.ibmStepCd === 'IS00000010' || result.col1.ibmStepCd === 'IS00000015' || result.col1.ibmStepCd === 'IS00000020') {
          this.requestImprRow.riskRegisterFlag = true
        }
        let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
        let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
        if (splitId && splitId.length > 0) {
          let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
          splitStep[idIndex] = result.col1.ibmStepName;
          this.requestImprRow.ibmStepNames = splitStep.toString();
        }
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.getList();
      }
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '선택된 위험등록부가 없습니다.', // 선택된 위험등록부가 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>